jQuery(document).ready(function () {

    // footer tag cloud - add count to DOM and keep top X
    if (jQuery('.product-tags-wrapper').get().length > 0) {
        // add count
        var footerTagCloud = [];
        jQuery('.product-tags-wrapper .product-tags .tagcloud > a').each(function (index, value) {
            var count = jQuery(value).attr('aria-label').match(/\d+/)[0];
            var tagData = {};
            tagData['count'] = count;
            tagData['DOM'] = jQuery(value)[0].outerHTML;
            jQuery(value).attr('data-count', count);
            footerTagCloud.push(tagData);
            jQuery(value).remove();
        });
        // sort tags by count
        footerTagCloud.sort(function (a, b) {
            return a.count - b.count;
        });
        footerTagCloud.reverse();
        // inject top 35 to DOM
        var tagsToKeep = 35;
        jQuery.each(footerTagCloud.slice(0, tagsToKeep), function (index, value) {
            jQuery('.product-tags-wrapper .product-tags .tagcloud').append(jQuery(value.DOM));
        });
    }

    // add whatsapp sharing to the product page
    if (jQuery('body.single-product').get().length > 0) {
        jQuery('.detail-container .share-buttons .social > ul').prepend('<li class="social-whatsapp" data-network="whatsapp" tabindex="0"><a href="whatsapp://send?text=Check this out! ' + window.location + '" data-action="share/whatsapp/share"><i class="fa fa-whatsapp"></i></a></li>');
    }

    // add read comic link to the product page
    if (jQuery('body.single-product').get().length > 0) {
        if (jQuery('#comic-link').get().length > 0) {
            var domToInject = '<br/><em>Read this comic <strong><a href="' + jQuery('#comic-link').attr('data-comic-link') + '" target="_blank">here</a></strong>.</em>'
            jQuery('.woocommerce-product-details__short-description').find('p').last().append(domToInject);
        }
    }

});
